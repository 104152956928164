import { httpGet, transformUnsafeTextNode } from './util.js';


export const Search = {
    template: `
        <div>
            <div>
                <div v-if="!error && results && results.length == 0" id="bottombar">
                    <h3>No results.</h3>
                </div>
                <div v-if="error">
                    <div class="box box-desc" id="bottombar">
                        <h3>Error: {{ error }}</h3>
                    </div>
                </div>
                <div v-if="!error && !results" id="bottombar">
                    <h3>Loading...</h3>
                </div>
                <div v-if="authoronly && author">
                     <div class="box box-profilepic" style="float: left; margin-left: 0;">
                        <img alt="Avatar" :src="'/asset/avatar/' + author.id" class="profilepic">
                     </div>
                     <div class="box box-usersearch">
                        <h2><i class="fas fa-user"></i> {{author.username}}</h2>
                        <p><b>Join Date:</b> {{author.registrationDate}}</p>
                        <p><b>Homepage:</b> <a target="_blank">{{author.homepage}}</a></p>
                    </div>
                </div>
                <div v-if="!error && results && results.length > 0">
                    <div id="heading" v-if="!authoronly">
                        {{resultCount}} results.
                        <div class="toolbox-button ocean kc" v-if="!useTableView" v-on:click="setUseTableView(true)">
                            <i class="va fa fa-list" aria-hidden="true"></i>
                        </div>
                        <div class="toolbox-button ocean kc" v-if="useTableView" v-on:click="setUseTableView(false)">
                            <i class="va fa fa-th-large" aria-hidden="true"></i>
                        </div>
                        <span id="rt">
                            Toggle View:
                        </span>
                        <div class="toolbox-button ocean kc" style="padding:6px;" v-if="typeFilter == 0" v-on:click="setTypeFilter(1)">
                            All
                        </div>
                        <div class="toolbox-button ocean kc" style="padding:6px;" v-if="typeFilter == 1" v-on:click="setTypeFilter(2)">
                            <i class="fas fa-video"></i>
                        </div>
                        <div class="toolbox-button ocean kc" style="padding:6px;" v-if="typeFilter == 2" v-on:click="setTypeFilter(0)">
                            <i class="fas fa-camera"></i>
                        </div>
                        <span id="rt">
                            Filter:
                        </span>
                        <router-link :to="{ path: '/search', query: { query : null, authoronly : 0, sortmode : -3 }}">
                            <div class="toolbox-button cherry kc">
                                <i class="va fas fa-star"></i> <div class="mobile">Rating</div>
                            </div>
                        </router-link>
                        <router-link :to="{ path: '/search', query: { query : null, authoronly : 0, sortmode : -4 }}">
                            <div class="toolbox-button cherry kc">
                                <i class="va fas fa-eye"></i> <div class="mobile">Views</div>
                            </div>
                        </router-link>
                        <span id="rt">
                            Best of:
                        </span>
                        <div class="toolbox-button gold kc" v-on:click="openRandomVideo()">
                            <i class="va fas fa-random"></i> <div class="mobile">{{randomRequested ? 'Please wait' : 'Random Result'}}</div>
                        </div>
                    </div>         

                </div>
                <div id="tview" v-if="useTableView">
                    <table v-if="!error && results && results.length > 0" class="sortable" id="res">
                        <thead>
                            <tr>
                                <th v-on:click="toggleSortMode(1)" style="max-width: 45%;">
                                    <i class="fas fa-font"></i>
                                    <div class="sortbutton" v-if="sortMode == 1 | sortMode == -1">
                                        <i :class="getSortStyle(1, 'fas fa-sort-alpha-up', 'fas fa-sort-alpha-down')"></i>
                                    </div>
                                </th>
                                <th style="width: 55px;" v-on:click="toggleSortMode(2)" v-if="typeFilter != 2">
                                    <i class="fas fa-stopwatch"></i>
                                    <div class="sortbutton" v-if="sortMode == 2 | sortMode == -2">
                                        <i :class="getSortStyle(2, 'fas fa-sort-amount-up', 'fas fa-sort-amount-down')"></i>
                                    </div>
                                </th>
                                <th class="rating" v-on:click="toggleSortMode(3)">
                                    <i class="fas fa-star"></i>
                                    <div class="sortbutton" v-if="sortMode == 3 | sortMode == -3">
                                        <i :class="getSortStyle(3, 'fas fa-sort-numeric-up', 'fas fa-sort-numeric-down')"></i>
                                    </div>
                                </th>
                                <th class="mobil" style="width: 70px;" v-on:click="toggleSortMode(4)">
                                    <i class="fas fa-eye"></i>
                                    <div class="sortbutton"  v-if="sortMode == 4 | sortMode == -4">
                                        <i :class="getSortStyle(4, 'fas fa-sort-numeric-up', 'fas fa-sort-numeric-down')"></i>
                                    </div>
                                </th>
                                <th v-on:click="toggleSortMode(5)">
                                    <i class="fas fa-user"></i>
                                    <div class="sortbutton"  v-if="sortMode == 5 | sortMode == -5">
                                        <i :class="getSortStyle(5, 'fas fa-sort-alpha-up', 'fas fa-sort-alpha-down')"></i>
                                    </div>                                    
                                </th>
                                <th class="mobi" v-on:click="toggleSortMode(6)" style="width: 195px;">
                                    <i class="fas fa-calendar"></i>
                                    <div class="sortbutton" v-bind:class="{ SortMode: 6 }" v-if="sortMode == 6 | sortMode == -6">
                                        <i :class="getSortStyle(6, 'fas fa-sort-amount-up', 'fas fa-sort-amount-down')"></i>
                                    </div>
                                </th>
                                <th class="mobi" style="text-align:right;"><i class="far fa-image"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!error && results && results.length > 0" v-for="res in results" class="result">
                                <td><b><router-link :to="{ name: 'view', params: { id: res.id }}">{{ transformUnsafeTextNode(res.title) }}</router-link></b></td>
                                <td v-if="typeFilter != 2">{{ ((( res.duration / 60)|0)*1) }}:{{ ( res.duration - (( res.duration / 60 )|0)*60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }}</td>
                                <td class="rating">
                                    <div class="ib">{{ (res.ratingsSum / res.ratingsCount / 2).toFixed(2) }}</div>
                                        <i v-if="(res.ratingsSum / res.ratingsCount / 2).toFixed(2) <= 1.5" class="far fa-star-half goldf"></i>
                                        <i v-else-if="(res.ratingsSum / res.ratingsCount / 2).toFixed(2) <= 3" class="far fa-star goldf"></i>
                                        <i v-else-if="(res.ratingsSum / res.ratingsCount / 2).toFixed(2) <= 4" class="fas fa-star-half goldf"></i>
                                        <i v-else class="fas fa-star goldf"></i>
                                    <div class="ibm">{{ (res.ratingsSum / res.ratingsCount / 2).toFixed(2) }}</div>
                                        <div class="ib">({{ res.ratingsCount }})</div>
                                </td>
                                <td class="mobil">{{ res.views }}</td>
                                <td><router-link :to="{ path: '/search', query: { query : res.username, authoronly : 1 }}">{{ res.username }}</router-link></td>
                                <td class="mobi">{{ res.uploadDate }}</td>
                                <td class="mobi" style="text-align:right;" v-on:mouseover="tableHoverRow = res.id;" v-on:mouseleave="tableHoverRow = tableHoverRow == res.id ? null : tableHoverRow;">
                                    <div style="pointer-events: none;"><i class="fa fa-camera-retro fa-lg"></i></div>
                                    <div v-if="tableHoverRow == res.id">
                                        <div style="position: absolute; pointer-events: none;"  >
                                            <img :src="'/asset/thumb/' + res.id" :alt="transformUnsafeTextNode(res.title)" class="rthumb" style="padding-top: 0;"/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="grid-tools" v-if="!useTableView && !error && results && results.length > 0" >
                    <span>Sort by:</span>
                    <div class="toolbox-button ocean" v-on:click="toggleSortMode(6)">
                        <i class="va far fa-calendar-alt"></i> <div class="mobile">Date</div>
                        <i v-if="sortMode == 6 | sortMode == -6" :class="getSortStyle(6, 'va fas fa-sort-amount-up', 'va fas fa-sort-amount-down')"></i>
                    </div>
                    <div class="mobil toolbox-button ocean" v-on:click="toggleSortMode(5)">
                        <i class="va fas fa-user"></i> <div class="mobile">Username</div>
                        <i v-if="sortMode == 5 | sortMode == -5" :class="getSortStyle(5, 'va fas fa-sort-alpha-up', 'va fas fa-sort-alpha-down')"></i>
                    </div>
                    <div class="toolbox-button ocean" v-on:click="toggleSortMode(4)">
                        <i class="va fas fa-eye"></i> <div class="mobile">Views</div>
                        <i v-if="sortMode == 4 | sortMode == -4" :class="getSortStyle(4, 'va fas fa-sort-numeric-up', 'va fas fa-sort-numeric-down')"></i>
                    </div>
                    <div class="toolbox-button ocean" v-on:click="toggleSortMode(3)">
                        <i class="va fas fa-star"></i> <div class="mobile">Rating</div>
                        <i v-if="sortMode == 3 | sortMode == -3" :class="getSortStyle(3, 'va fas fa-sort-numeric-up', 'va fas fa-sort-numeric-down')"></i>
                    </div>
                    <div class="toolbox-button ocean" v-on:click="toggleSortMode(2)"v-if="typeFilter != 2">
                        <i class="fas va fa-stopwatch"></i> <div class="mobile">Duration</div>
                        <i v-if="sortMode == 2 | sortMode == -2" :class="getSortStyle(2, 'va fas fa-sort-amount-up', 'va fas fa-sort-amount-down')"></i>
                    </div>
                    <div class="toolbox-button ocean" v-on:click="toggleSortMode(1)">
                        <i class="va fas fa-font"></i> <div class="mobile">Title</div>
                        <i v-if="sortMode == 1 | sortMode == -1" :class="getSortStyle(1, 'va fas fa-sort-alpha-up', 'va fas fa-sort-alpha-down')"></i>
                    </div>
                </div>
                <div id="bview" v-if="!useTableView">
                    <div v-if="!error && results && results.length > 0" v-for="res in results" class="box box-results">
                        <router-link :to="{ name: 'view', params: { id: res.id }}"><div :style="'background-image: url(&quot;/asset/thumb/' + res.id + '&quot;)'" :alt="res.title" class="rthumb"></div></router-link>
                        <router-link :to="{ name: 'view', params: { id: res.id }}" class="vaw" :title="transformUnsafeTextNode(res.title)"><div id="grid-button">{{ transformUnsafeTextNode(res.title) }}</div></router-link>
                        <div id="stats">
                            <i class="fas fa-user"></i> <router-link :to="{ path: '/search', query: { query : res.username, authoronly : 1 }}">{{ res.username }}</router-link><br/>
                            <div style="display: inline-block;" v-if="res.type === 'video'"><i class="fas fa-stopwatch"></i> {{ ((( res.duration / 60)|0)*1) }}:{{ ( res.duration - (( res.duration / 60 )|0)*60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }}</div>
                            <div style="display: inline-block;" v-if="res.type != 'video'"><i class="fas fa-camera"></i></div> | 
                            <div style="display: inline-block;" v-bind:title="res.ratingsCount + ' Votes'"><i v-if="(res.ratingsSum / res.ratingsCount / 2).toFixed(2) <= 1.5" class="far fa-star-half goldf"></i>
                                   <i v-else-if="(res.ratingsSum / res.ratingsCount / 2).toFixed(2) <= 3" class="far fa-star goldf"></i>
                                   <i v-else-if="(res.ratingsSum / res.ratingsCount / 2).toFixed(2) <= 4" class="fas fa-star-half goldf"></i>
                                   <i v-else class="fas fa-star goldf"></i> {{ (res.ratingsSum / res.ratingsCount / 2).toFixed(2) }}</div> | <i class="fas fa-eye"></i> {{ res.views }}<br/>
                       </div>
                    </div>
                </div>
                <div class="box box-desc" id="bottombar" v-if="!error && results && results.length != 0">
                    <div class="va" v-if="results.length === resultCount"><h4>End of search results.</h4></div>
                    <div class="va" v-if="results.length != resultCount"><h4>Endless Scrolling: {{resultCount - results.length}} more results omitted.</h4></div>
                </div>
            </div>
        </div>
        
    `,
    created() {
        this.load();
    },
    watch: {
        '$route': 'load',
    },
    data: function () {
        return {
            query: '',
            results: null,
            error: null,
            resultCount: null,
            page: 0,
            pageRequested: 0,
            typeFilter: 0,
            useTableView: false,
            randomRequested: false,
            sortMode: -3,
            author: null,
            tableHoverRow: null,
        }
    },
    methods: {
        loadPage() {
            let requestedPage = this.page;
            this.requestedPage = requestedPage;
            httpGet('/api/videos?query=' + encodeURIComponent(this.query) + "&authoronly=" + encodeURIComponent(this.authoronly ? "1" : "0") + "&typefilter=" + encodeURIComponent(this.typeFilter) + "&sortmode=" + this.sortMode + "&page=" + requestedPage, (results, error) => {
                this.page = requestedPage;
                if (results !== null) {
                    this.results = this.results || [];
                    this.results.push(...results);
                }
                this.error = error;
            });
        },
        loadResultCount() {
            httpGet('/api/videos/resultcount?query=' + encodeURIComponent(this.query) + "&authoronly=" + encodeURIComponent(this.authoronly ? "1" : "0") + "&typefilter=" + encodeURIComponent(this.typeFilter), (resultCount, error) => {
                if (resultCount !== null) {
                    this.resultCount = resultCount.count;
                }
            });
        },
        loadAuthorInfo() {
            httpGet('/api/user?query=' + encodeURIComponent(this.query), (author, error) => {
                if (author !== null) {
                    this.author = author;
                }
            });
        },
        load() {
            this.useTableView = (localStorage.getItem("searchUseTableView") || "0") == "1";
            this.query = this.$route.query.query || '';
            this.authoronly = (this.$route.query.authoronly || '') == '1';
            this.typeFilter = parseInt(this.$route.query.typefilter || '0');
            if (isNaN(this.typeFilter)) this.typeFilter = 0;
            this.sortMode = this.$route.query.sortmode || -3;
            this.page = 1;
            this.pageRequested = 0;
            this.results = null;
            this.resultCount = null;
            this.error = null;
            this.loadPage();
            this.loadResultCount();
            this.randomRequested = false;
            this.author = null;
            this.tableHoverRow = null;

            if (this.authoronly) {
                this.loadAuthorInfo();
            }
        },
        scroll() {
            if ((window.innerHeight + Math.ceil(window.pageYOffset)) >= document.body.offsetHeight - 10) {
                // not already loading one
                if (this.page == this.requestedPage && this.results) {
                    // not at the end of all data
                    if (this.results.length == this.page * 50) {
                        // load next page
                        this.page++;
                        this.loadPage();
                    }
                }
            }
        },
        openRandomVideo() {
            if (!this.resultCount || this.resultCount <= 0 || this.randomRequested) {
                return
            }

            // Debounce
            this.randomRequested = true;

            let offset = Math.floor(Math.random() * this.resultCount);
            let page = Math.floor(offset / 50);
            let pageOffset = offset % 50;

            // Load this page
            httpGet('/api/videos?query=' + encodeURIComponent(this.query) + "&authoronly=" + encodeURIComponent(this.authoronly ? "1" : "0") + "&typefilter=" + encodeURIComponent(this.typeFilter) + "&page=" + page, (results, error) => {
                if (results !== null) {
                    if (pageOffset < results.length) {
                        this.$router.push({ name: 'view', params: { id: results[pageOffset].id } });
                    }
                }

                this.randomRequested = false;
            });
        },
        setUseTableView(use) {
            this.useTableView = use;
            localStorage.setItem('searchUseTableView', use ? "1" : "0");
        },
        toggleSortMode(modeEnum) {
            let newSortMode;
            if (this.sortMode == modeEnum) {
                newSortMode = -modeEnum;
            } else {
                newSortMode = modeEnum;
            }
            this.$router.push({ path: '/search', query: { query: this.query, authoronly: this.authoronly, typefilter: this.typeFilter, sortmode: newSortMode } });
        },
        setTypeFilter(typeFilter) {
            this.$router.push({ path: '/search', query: { query: this.query, authoronly: this.authoronly, typefilter: typeFilter, sortmode: this.sortMode } });
        },
        getSortStyle(modeEnum, stylePrimary, styleSecondary) {
            if (this.sortMode == modeEnum) {
                return stylePrimary;
            } else if (this.sortMode == -modeEnum) {
                return styleSecondary;
            } else {
                return stylePrimary + " sortdisabled";
            }
        },
        transformUnsafeTextNode: transformUnsafeTextNode,
    },
    beforeMount() {
        window.addEventListener('scroll', this.scroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scroll);
    }
};