import Vue from './vue.js';
import VueRouter from './vue-router.js';
import { Index } from './index.js';
import { Search } from './search.js';
import { View } from './view.js';

Vue.use(VueRouter);
Vue.component('search', Search);

(function () {

    const routes = [
        { path: '/', component: Index },
        { path: '/search', component: Search },
        { name: 'view', path: '/view/:id', component: View }
    ];

    const router = new VueRouter({
        mode: 'history',
        routes: routes
    })

    var app = new Vue({
        el: '#app',
        router: router,
        data: {
            queryinput: '',
            results: []
        },
        methods: {
            search: function () {
                router.push({ path: '/search', query: { query: this.queryinput } });
            }
        },
    });
})();