export function httpGet(url, callback) {
    $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
            callback(data, null);
        },
        error: function (data) {
            callback(null, data);
        }
    });
}

// from https://stackoverflow.com/questions/7753448/how-do-i-escape-quotes-in-html-attribute-values
function escapeAttr(s, preserveCR) {
    preserveCR = preserveCR ? '&#13;' : '\n';
    return ('' + s) /* Forces the conversion to string. */
        .replace(/&/g, '&amp;') /* This MUST be the 1st replacement. */
        .replace(/'/g, '&apos;') /* The 4 other predefined entities, required. */
        .replace(/"/g, '&quot;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        /*
        You may add other replacements here for HTML only 
        (but it's not necessary).
        Or for XML, only if the named entities are defined in its DTD.
        */
        .replace(/\r\n/g, preserveCR) /* Must be before the next replacement. */
        .replace(/[\r\n]/g, preserveCR);
    ;
}

// from https://stackoverflow.com/questions/6234773/can-i-escape-html-special-chars-in-javascript
function escapeHtml(html) {
    var text = document.createTextNode(html);
    var div = document.createElement('div');
    div.appendChild(text);
    return div.innerHTML;
}

// from https://stackoverflow.com/questions/5796718/html-entity-decode
var decodeEntities = (function () {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');

    function decodeHTMLEntities(str) {
        if (str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    return decodeHTMLEntities;
})();

export function transformUnsafeTextBlock(html) {
    function urlTransformer(url) {
        return url;
    };
    
    function classIdTransformer(name) {
        return null;
    };

    // TODO: conflicts with pre-existing <a> tags...
    // auto-detect links, really ugly...
    //var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    //let linked = html.replace(urlRegex, function (url) {
    //    return '<a href="' + escapeAttr(url) + '">' + escapeHtml(url) + '</a>';
    //});
    let linked = html;
    let linebreaked = linked.replace(/\n/g, "<br/>"); // \n -> <br/>

    let sanitized = html_sanitize(linebreaked, urlTransformer, classIdTransformer)

    return sanitized;
}

export function transformUnsafeTextNode(html) {
    return decodeEntities(html);
}