import { httpGet } from './util.js';

export const Index = {
    template: `
        <div>
            <div class="box box-index">
                <div id="vheading"><h3>Welcome to the TM-Tube Archive!</h3></div>
                <div id="ct">
                    <p><a href="https://www.youtube.com/watch?v=W-lDiRFxcUI" target="_blank">Release video</a></p><p><strong>TM-Tube is back.</strong> After almost 2 years of absence and 4 months of freetime work, a little team of dedicated Trackmania community members have brought back the memories of what was once the most popular media sharing hub for Trackmania players.</p>
					
<h4>What am I seeing here?</h4>

<hr>
					
<p>This is merely a static archive. You can search through all videos and images ever published on TM-Tube, see comments and read stats. We decided to only include the bare minimum of features that you'll need to enjoy your experience here. The goal: Giving the community their old memories back. A lot of amazing content has been uploaded to TM-Tube and it would be a shame if it all was just forgotten.</p>

<p>New features include: Endless scrolling, improved search and sorting, improved search results, mobile phone compability and a better video player.</p>

<h4>Background</h4>

<hr>

<p>The excellent co-operation between x2x (TM-Tube admin) and the MX TM-Tube Delegation (Tiggs, Ozon, Eyebo) made this project possible.</p>

<p>For a very long time, TM-Tube was the most popular media sharing page for Trackmaniacs. Created by x2x in 2008, the site was way ahead of its time, allowing for 60+FPS video and h264 encoding before it was becoming popular. Not only did TM-Tube serve as a media hub, but also as a meeting point of many creative folks in the community. Friendships were forged on that site, incredible amounts of ideas were shared among the community members around and entertaining chats were had.</p>

<p>With x2x not being able to update the website to the newest - and competitive - technical and security standards, the site had to shut down temporarily in 2015. For two years, the data has been sleeping on x2x's own server. After establishing contact between both parties, the files were transferred to the MX-servers and the work had started on getting a nice looking page going. Tiggs was responsible for the entire backend (code, database, encoding, filetransfer), while Ozon worked on the frontend (html, css). Hopefully we can guarantee for a bugfree experience!</p>

<hr>


<p>With the completion of this archive, we (Ozon, Tiggs, Eyebo) want to thank x2x with all our hearts for the work and passion he has put into creating, maintaining and also handing over TM-Tube.</p>

<p>If you like to discuss, report a problem or express your own thanks for x2x, we have created a <a href="https://tm.mania-exchange.com/threads/4643/tm-tube-archive-megathread?page=-1" target="_blank">TM-Tube Megathread</a> on MX.</p>

</p><p>At last, we wish you a lot of fun with browsing the archive!<br>
<i>- <a href="https://tm.mania-exchange.com/user/profile/1814" target="_blank">Ozon</a>, <a href="https://tm.mania-exchange.com/user/profile/268" target="_blank">Tiggs</a>, <a href="https://tm.mania-exchange.com/user/profile/15" target="_blank">Eyebo</a></i></p>
                </div>
            </div>  

            <search/>
        </div>
    `,
    created() {
        this.load();
    },
    watch: {
        '$route': 'load',
    },
    data: function () {
        return {
        }
    },
    methods: {
        load() {
        }
    }
};