import { httpGet, transformUnsafeTextBlock, transformUnsafeTextNode } from './util.js';
import Vue from './vue.js';

export const View = {
    template: `
        <div>
            <div v-if="!error">
                <div v-if="video.online == 1" class="box box-video">
                    <video v-if="video && video.type == 'video'" :src="'/asset/stream/' + video.id" style="max-width: 100%;" :poster="'/asset/thumb/' + video.id" type="video/mp4"></video>
                    <a :href="video ? '/asset/image/' + video.id : ''" target="_blank"><img v-if="video && video.type == 'photo'" :src="'/asset/image/' + video.id" style="max-width: 100%;"/></a>
                    <div v-if="!video" style="background-color: black;"></div>
                </div>
<div class="box box-index" v-if="video.online === 0"><div id="vheading"><h3>Video unavailable</h3></div><div id="ct"><p><i class="fas fa-exclamation-triangle"></i> <strong>This video is currently unavailable.</strong> You can request it to be reuploaded. Contact the moderators on <a href="https://tm.mania-exchange.com/messaging/compose/11" target="_blank">ManiaExchange</a> or their <a href="https://discord.mania.exchange/" target="_blank">Discord</a>.</p></div></div>
                <div v-if="video">
                    <div class="box-profilepic">
                        <img alt="Avatar" :src="'/asset/avatar/' + author.id" class="profilepic"/>
                    </div>
                    <div class="box box-content">
                        <h2><div style="display: inline-block;" v-if="video.type === 'video'"><i class="fas fa-video"></i></div><div style="display: inline-block;" v-if="video.type != 'video'"><i class="fas fa-camera"></i></div> {{ transformUnsafeTextNode(video.title) }}</h2>
                    </div>
                    <div class="box box-stats">
                        <i class="fas fa-eye"></i> <b>{{ video.views }}</b> views<br/>
                        <i class="fas fa-star"></i> <b>{{ (video.ratingsSum / video.ratingsCount / 2).toFixed(2) }}</b> ({{ video.ratingsCount }} votes)<br/>
                        ID <b>{{ video.id }}</b> / <i class="fas fa-calendar-alt"></i> <b>{{ video.uploadDate }}</b><br/>
                    </div>
                    <div class="box box-user">
                        <h2><router-link :to="{ path: '/search', query: { query : author.username, authoronly : 1 }}">{{ author.username }}</router-link> <i class="fas fa-user"></i></h2>
                        <p><b>Join Date:</b> {{ author.registrationDate }}</p>
                    </div>
                    <div class="box box-desc">
                        <div id="vheading">
                            <h4><i class="fas fa-bars"></i> Description</h4>
                        </div>
                        <div id="ct">
                            <p v-html="transformUnsafeTextBlock(video.description)"></p>
                        </div>
                    </div>
                    <div class="box box-desc">
                        <div id="vheading">
                            <h4><i class="fas fa-bars"></i> Time Related Media</h4>
                        </div>
                        <div id="ct" v-if="!related">
                            Loading...
                        </div>
                        <div id="ct" v-else-if="related">
                            <div id="bview">
                                <div v-for="relate in related" class="box box-results">
                                    <a :href="'/view/' + relate.id"><div :style="'background-image: url(&quot;/asset/thumb/' + relate.id + '&quot;)'" :alt="relate.title" class="rthumb"></div></a>
                                    <a :href="'/view/' + relate.id" class="vaw" :title="transformUnsafeTextNode(relate.title)"><div id="grid-button">{{ transformUnsafeTextNode(relate.title) }}</div></a>
                                    <div id="stats">
                                        <i class="fas fa-user"></i> <router-link :to="{ path: '/search', query: { query : relate.username, authoronly : 1 }}">{{ relate.username }}</router-link><br/>
                                        <div style="display: inline-block;" v-if="relate.type === 'video'"><i class="fas fa-stopwatch"></i> {{ ((( relate.duration / 60)|0)*1) }}:{{ ( relate.duration - (( relate.duration / 60 )|0)*60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }}</div>
                                        <div style="display: inline-block;" v-if="relate.type != 'video'"><i class="fas fa-camera"></i></div> | 
                                        <div style="display: inline-block;" v-bind:title="relate.ratingsCount + ' Votes'">
                                            <i v-if="(relate.ratingsSum / relate.ratingsCount / 2).toFixed(2) <= 1.5" class="far fa-star-half goldf"></i>
                                            <i v-else-if="(relate.ratingsSum / relate.ratingsCount / 2).toFixed(2) <= 3" class="far fa-star goldf"></i>
                                            <i v-else-if="(relate.ratingsSum / relate.ratingsCount / 2).toFixed(2) <= 4" class="fas fa-star-half goldf"></i>
                                            <i v-else class="fas fa-star goldf"></i> {{ (relate.ratingsSum / relate.ratingsCount / 2).toFixed(2) }}
                                        </div>
                                        | <i class="fas fa-eye"></i> {{ relate.views }}<br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box box-comments">
                        <div id="vheading"><h4><i class="fas fa-comments"></i> Comments ({{ video.commentsCount }})</h4></div>
                        <div id="ctc" v-for="comment in comments">
                            <div class="box-commentavatar">
                                <i><img alt="Avatar" :src="'/asset/avatar/' + comment.author.id" class="commentpic"></i>
                            </div>
                            <div class="box-singlecomment">
                                <div id="vheading"><i class="fas fa-user"></i> <b><router-link :to="{ path: '/search', query: { query : comment.author.username, authoronly : 1 }}">{{ comment.author.username }}</router-link></b> - <small>{{ comment.comment.time }}</small></div>
                                <div id="ctc">
                                    <p v-html="transformUnsafeTextBlock(comment.comment.text)"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!video" id="bottombar">
                        <h3>Loading...</h3>
                    </div>
                </div>
            </div>
            <div v-if="error" id="bottombar">
                <h3>Error.</h3>
            </div>
       </div>
    `,
    created() {
        this.load();
    },
    watch: {
        '$route': 'load'
    },
    data: function () {
        return {
            video: null,
            related: null,
            error: null
        }
    },
    methods: {
        load() {
            this.video = null;
            this.related = null;
            this.error = null;

            httpGet('/api/videos/' + this.$route.params.id, (data, error) => {
                if (!data && !error) error = 'Video not found.';

                this.video = data.video;
                this.comments = data.comments;
                this.author = data.author;
                this.error = error;

                if (this.video) {
                    Vue.nextTick(function () {
                        $('video').mediaelementplayer({
                            pluginPath: 'https://cdnjs.com/libraries/mediaelement/',
                            shimScriptAccess: 'always'
                        });
                    });
                }
            });
            httpGet('/api/videos/' + this.$route.params.id + '/related', (related, error) => {
                if (related) {
                    this.related = related;
                }
            });
        },
        transformUnsafeTextBlock: transformUnsafeTextBlock,
        transformUnsafeTextNode: transformUnsafeTextNode,
    }
};